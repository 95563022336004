<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Bot Provisioning Wizard</h2>
            <hr class="h2" />
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">2</div> <span class="step">FAQs & Action Triggers</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="num">3</div> Email & SMS Actions
                <span class="arrow">&#8594;</span>
                <div class="num">4</div> Transfers
                <span class="arrow">&#8594;</span>
                <div class="num">5</div> Gen AI
                <span class="arrow">&#8594;</span>
                <div class="num">6</div> Test and Finish
            </div>
            <!--FAQs-->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000e8cbb1be20d545c4a6fa/emb" :is="'script'">
                </component>
            </div>
            <div v-if="showActionTriggers">
                <!--Tiered FAQs-->
                <div class="container">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd0001b0a2255a83b474ea816/emb" :is="'script'">
                    </component>
                </div>
                <!--Tiered FAQ conditional responses-->
                <div class="container">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd0007ef2eddbfddf4cadb1cd/emb" :is="'script'">
                    </component>
                </div>
                <!--Action Triggers-->
                <div class="container">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd0004b711106124741599e3e/emb" :is="'script'">
                    </component>
                </div>
            </div>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00029703ce273bf44de8fdb/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'MainConfig',
    components: {
        SideBar
    },
    data() {
        return {
            showActionTriggers: true,
        };
    },
    mounted() {
        this.checkBotTier();
    },
    methods: {
        checkBotTier() {
            const params = new URLSearchParams(window.location.search);
            const botTier = params.get("Bot_Tier");

            if (botTier && botTier.toLowerCase() === 'poc') {
                this.showActionTriggers = false;
            }
        }
    }
}
</script>
