<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Bot Provisioning Wizard</h2>
            <hr class="h2" />
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step"> FAQs & Action Triggers</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">3</div> <span class="step">Email & SMS Actions</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="num">4</div> Transfers
                <span class="arrow">&#8594;</span>
                <div class="num">5</div> Gen AI
                <span class="arrow">&#8594;</span>
                <div class="num">6</div> Test and Finish
            </div>
            <br>
            <br>
            <h3>Data Collection</h3>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd0005b5cfcda4fe445ad8acc/emb" :is="'script'">
                </component>
            </div>

            <h3>Email Requests</h3>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00036a5bd7b5d6f41f6aac5/emb" :is="'script'">
                </component>
            </div>
            <h3>SMS Notifications</h3>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000d0bd6bec6fd942b1a8dd/emb" :is="'script'">
                </component>
            </div>
            <!-- Submit button -->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00034bc88d475df40a9be11/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'BotData',
    components: {
        SideBar
    }
}
</script>