<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000467aeb04f5bc4451b5fd/emb" :is="'script'">
                </component>
            </div>
            <h2>Tiered FAQs</h2>
            <hr class="h2" />
            <div class="container">
                <!-- <component src="https://c2hbe361.caspio.com/dp/76bfd000d07b4f19d66e4c158dfa/emb" :is="'script'">
                </component> -->
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'BotTFAQs',
    components: {
        SideBar
    }
}
</script>